<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2">
                <h3>Новое вознаграждение</h3>
            </b-card-header>

            <salary-bonus-form v-model='bonus'></salary-bonus-form>
        </b-card>

        <control-panel>
            <b-button variant="danger" :to='routerSalaryBonusList()'>К списку</b-button>
            <b-button variant="success" @click='createSalaryBonus()'>Создать</b-button>
        </control-panel>
    </div>
</template>

<script>
    import moment from 'moment';
    import cloneDeep from 'lodash/cloneDeep';

    import ControlPanel from '../../_common/ControlPanel';
    import ErrorsBagList from '../../_common/ErrorsBagList';

    import SalaryBonusForm from './SalaryBonusForm';

    import ErrorsBag from '../../../utils/errorsBag';
    import { SALARY_ENDPOINT } from '../../../utils/endpoints';

    const DEFAULT_SALARY_BONUS = {
        config: [],
        valid_from: null,
        workPosition: null,
        calculationType: null,
        responsibilityType: null
    };

    export default {
        name: "SalaryBonusCreate",
        components: { ControlPanel, ErrorsBagList, SalaryBonusForm },
        data() {
            return {
                moment,
                ErrorsBag,
                bonus: null
            };
        },
        methods: {
            routerSalaryBonusList() {
                return { name: 'SalaryBonusList' };
            },

            createSalaryBonus() {
                ErrorsBag.discard();

                const bonusConfig = this.bonus.config.reduce((acc, el) => {
                    if (el.attribute.key === "check_type_kpi_sanitary_rate") {
                        el.value = this.bonus?.checkType?.id
                    }

                    if (el.attribute.key === "addition_kpi_sanitary_rate" ) {
                        el.value = this.bonus?.addition?.id
                    }

                    acc = [...acc, el]
                    return acc
                }, [])

                let params = {
                    config: bonusConfig,
                    valid_from: moment( this.bonus.valid_from ).format( 'YYYY-MM' ),
                    work_position: { id: this.bonus.workPosition ? this.bonus.workPosition.id : null },
                    calculation_type: { id: this.bonus.calculationType ? this.bonus.calculationType.id : null },
                    responsibility_type: { id: this.bonus.responsibilityType ? this.bonus.responsibilityType.id : null },
                    check_type: { id: this.bonus?.salaryBonusCheckType?.id },
                    addition: { id: this.bonus?.salaryBonusAddition?.id},
                };

                this.$http
                    .post( SALARY_ENDPOINT + '/bonus', params )
                    .then(
                        () => this.$router.push( { name: 'SalaryBonusList' } ),
                        response => ErrorsBag.fill( response.body )
                    );
            }
        },
        mounted() {
            this.bonus = cloneDeep( DEFAULT_SALARY_BONUS );
        }
    }
</script>
